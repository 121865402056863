'use strict';

window.setJSTriggers = () => {

    let jsNewContact = document.querySelectorAll('.js-new-contact');
    if (jsNewContact[0]) {
        jsNewContact.forEach((trigger) => {
            let newContactKeyUp = () => {
                let contactName = document.querySelectorAll('[name="contact_name[]"]')[0];
                if (trigger.value.length >= 1) {
                    contactName.value = trigger.value;
                }
            };
            addExtraContact();
            trigger.addEventListener('keyup', newContactKeyUp);

            trigger.addEventListener('blur', () => {
                trigger.removeEventListener('keyup', newContactKeyUp);
            });
        });
    }

    let jsEmailPhoneMask = document.querySelectorAll('.js-email-phone-mask');
    if (jsEmailPhoneMask[0]) {
        jsEmailPhoneMask.forEach((trigger) => {
            trigger.addEventListener('keydown', () => {
                if (!isNaN(trigger.value) && trigger.value.length >= 1 && !trigger.classList.contains('mask-phone-without-country')){
                    trigger.classList.add('mask-phone-without-country');
                    setMasks();
                    moveCursorToEnd(trigger);
                } else if (trigger.value.length === 0){
                    trigger.classList.remove('mask-phone-without-country');
                    setMasks();
                } else if (trigger.value.length > 0 && trigger.value[0] === '(' && !trigger.classList.contains('mask-phone-without-country')){
                    trigger.classList.add('mask-phone-without-country');
                    setMasks();
                } else if (isNaN(trigger.value) && (trigger.value.length === 1 || trigger.value.length === 0)) {
                    trigger.classList.remove('mask-phone-without-country');
                    setMasks();
                }
            });

            trigger.addEventListener('blur', () => {
                if (!isNaN(trigger.value) && trigger.value.length >= 1 && !trigger.classList.contains('mask-phone-without-country')){
                    trigger.classList.add('mask-phone-without-country');
                    setMasks();
                    moveCursorToEnd(trigger);
                } else if (trigger.value.length === 0){
                    trigger.classList.remove('mask-phone-without-country');
                    setMasks();
                } else if (trigger.value.length > 0 && trigger.value[0] === '(' && !trigger.classList.contains('mask-phone-without-country')){
                    trigger.classList.add('mask-phone-without-country');
                    setMasks();
                } else if (isNaN(trigger.value) && (trigger.value.length === 1 || trigger.value.length === 0)) {
                    trigger.classList.remove('mask-phone-without-country');
                    setMasks();
                }
            });
        });
    }

    // Select2
    /*let jsSelect2 = document.querySelectorAll('.js-select2');
    if (jsSelect2[0]) {
        jsSelect2.forEach((element) => {
            let jQueryElement = $('#' + element.id);
            let closeOnSelect = (element.dataset.closeOnSelect !== 'true');
            let parent        = element.dataset.parent;
            let select2parent = parent ? $(parent) : $('body');
            let addNewItems   = (element.dataset.addNew === 'true');

            jQueryElement.select2({
                createTag        : function (params) {
                    if (addNewItems) {
                        return {
                            id       : '{{new}}' + params.term,
                            text     : params.term,
                            newOption: true
                        }
                    }
                },
                templateResult   : function (data) {
                    let result = $("<span></span>");
                    result.text(data.text);
                    if (data.newOption && addNewItems && (data.text.length >= 3)) {
                        result.append(" <em>(novo)</em>");
                    }
                    return result;
                },
                dropdownAutoWidth: true,
                width            : '100%',
                dropdownParent   : select2parent,
                tags             : true,
                closeOnSelect    : closeOnSelect
            }).on(
                'select2:close',
                function () {
                    $(this).focus();
                }
            );
        });
    }*/

    // Sidepanel triggers
    let loadSidepanelTrigger = document.querySelectorAll('.js-load-sidepanel');
    if (loadSidepanelTrigger[0]) {
        loadSidepanelTrigger.forEach((trigger) => {
            trigger.onclick = function () {
                window.location.hash = 'action=show&url=' + trigger.dataset.url;
            };
        });
    }

    // Load the service events index
    let serviceEventsLoad = document.querySelectorAll('.js-service-events-load');
    if (serviceEventsLoad[0]) {
        serviceEventsLoad.forEach((serviceEventsLoad) => {
            serviceEventsLoad.onclick = () => {
                loadView(serviceEventsLoad.dataset.url, null, 'service-events-container');
            };
        });
    }

    // Modal triggers
    let jsModal = document.querySelectorAll('.js-modal');
    if (jsModal[0]) {
        jsModal.forEach((trigger) => {
            trigger.onclick = () => {
                window.location.hash = 'action=show.modal&url=' + trigger.dataset.url;
            };
        });
    }

    // Notifications
    let jsServicesNeedActionNotification = document.querySelectorAll('.js-services-need-action-notification');
    if (jsServicesNeedActionNotification[0]) {
        jsServicesNeedActionNotification.forEach((element) => {
            notify('',
                'Existem OSes sem um técnico designado. <br>Verifique o ícone de ' +
                '<i class="zmdi zmdi-notifications text-white"></i> no canto superior direito.',
                '60000', 'warning', 'top', 'center');
            element.classList.remove('js-services-need-action-notification');
        });
    }

    // Autosave checklist
    let jsChecklistAutoSave = document.querySelectorAll('.js-checklist-auto-save');
    if (jsChecklistAutoSave[0]) {
        window.submitTimeout = (element, time) => {
            let formId = element.dataset.formId;
            let form   = document.getElementById(formId);
            window.clearTimeout(timedSave);
            timedSave = setTimeout(function () {
                form.submit();
            }, time);
        };

        let timedSave = setTimeout(() => {
        }, 2000);
        jsChecklistAutoSave.forEach((element) => {
            let type = element.getAttribute('type');
            if (type === 'checkbox') {
                element.addEventListener('change', () => {
                    submitTimeout(element, 2000);
                });
            } else {
                element.addEventListener('keyup', () => {
                    submitTimeout(element, 1000);
                });
            }

        });
    }
};

function pullData() {
    let t;
    let ft;

    window.onload       = resetTimer;
    window.onmousemove  = resetTimer;
    window.onmousedown  = resetTimer;
    window.ontouchstart = resetTimer;
    window.onclick      = resetTimer;
    window.onkeypress   = resetTimer;
    window.addEventListener('scroll', resetTimer, true);

    function stopFetchServer() {
        clearInterval(ft);
        ft = null;
        //console.log('Stopping fetch server');
    }

    function resetTimer() {
        clearTimeout(t);
        t = setTimeout(stopFetchServer, 16000);

        if (ft === undefined || ft === null) {
            //console.log('Resume fetching server...');
            getServicesWithoutUsers();
            ft = setInterval(getServicesWithoutUsers, 15000);
        }
    }
}

if (document.getElementsByClassName('js-pull-data')[0]) {
    pullData();
}

function getServicesWithoutUsers() {
    //console.log('Executing: getServicesWithoutUsers');
    ajaxSave('GET', '/services/without-user', '', renderServicesWithoutUsersList);
}


/**
 * Render the services that have no users yet (top bar)
 *
 */
function renderServicesWithoutUsersList(data) {
    let services = data.services;
    //console.log(services);
    let servicesList       = document.getElementById('services-without-user-list');
    let newListItems       = '';
    if (servicesList){
        servicesList.innerHTML = '';
        services.forEach((service) => {
            newListItems += '<a href="javascript:void(0)" class="listview__item js-load-sidepanel" data-url="' + service.url + '">' +
                '<img src="/img/blank-avatar.png" class="listview__img" alt="">' +
                '<div class="listview__content">' +
                '<div class="listview__heading">' + service.id + '</div>' +
                '<p>Criado ' + service.created_at_for_humans + '</p>' +
                /*'<p>Liberado há 1 hora</p>' +*/
                '</div>' +
                '</a>';
        });
        servicesList.innerHTML = newListItems;
        setJSTriggers();
    }
}

/**
 * @custom
 * OnPopState: verify our location.hash and trigger verifyHash() on every popstate
 *
 */
window.onpopstate = function (event) {
    verifyHash();
};

/*------------------------------------------------
    Page Loader
-------------------------------------------------*/
$(window).on('load', function () {
    setTimeout(function () {
        $('.page-loader').fadeOut();
    }, /*500 @custom ---> */ 100);
});

$(document).ready(function () {

    /*------------------------------------------------
        Global modal
    -------------------------------------------------*/
    $('#global-modal').on('hidden.bs.modal', () => {
        //location.hash = '_';
        history.back();
    });

    /*------------------------------------------------
        General JS triggers
    -------------------------------------------------*/
    setJSTriggers();

    /*------------------------------------------------
        Search
    -------------------------------------------------*/
    let documentBody = $('body');
    // Active Stat
    documentBody.on('focus', '.search__text', function () {
        $(this).closest('.search').addClass('search--focus');
    });

    // Clear
    documentBody.on('blur', '.search__text', function () {
        $(this).val('');
        $(this).closest('.search').removeClass('search--focus');
    });


    /*------------------------------------------------
        Sidebar toggle menu
    -------------------------------------------------*/
    documentBody.on('click', '.navigation__sub > a', function (e) {
        e.preventDefault();

        $(this).parent().toggleClass('navigation__sub--toggled');
        $(this).next('ul').slideToggle(250);
    });


    /*------------------------------------------------
        Form group blue line
    -------------------------------------------------*/
    // @custom: switched to formGroupBlueLine()
    /*if($('.form-group--float')[0]) {
        $('.form-group--float').each(function () {
            var p = $(this).find('.form-control').val()

            if(!p.length == 0) {
                $(this).find('.form-control').addClass('form-control--active');
            }
        });

        $('body').on('blur', '.form-group--float .form-control', function(){
            var i = $(this).val();

            if (i.length == 0) {
                $(this).removeClass('form-control--active');
            }
            else {
                $(this).addClass('form-control--active');
            }
        });

        $(this).find('.form-control').change(function () {
            var x = $(this).val();

            if(!x.length == 0) {
                $(this).find('.form-control').addClass('form-control--active');
            }
        });
    }*/
    formGroupBlueLine();

    /*------------------------------------------------
     @custom: verify Hash in the window address bar
     -------------------------------------------------*/
    verifyHash();

    /*------------------------------------------------
     @custom: Show / Hide edit icon from list views
     -------------------------------------------------*/
    toggleListItemActions();

    /*------------------------------------------------
     @custom: Show / Hide search form when in XS
     -------------------------------------------------*/
    toggleSearchForm();

    /*------------------------------------------------
     @custom: Shortcuts
     -------------------------------------------------*/
    document.onkeyup = function (e) {
        var key = e.which || e.keyCode || e.key;
        if (e.altKey && key === 49) // alt + 1
            document.getElementById('service-filter-1').click();

        if (e.altKey && key === 50) // alt + 2
            document.getElementById('service-filter-2').click();

        if (e.altKey && key === 51) // alt + 3
            document.getElementById('service-filter-3').click();

        if (e.altKey && key === 83) // alt + s
            document.getElementById('go-to-view-service').click();

        if (e.altKey && key === 75) // alt + k
            document.getElementById('go-to-view-service-kanban').click();

        if (e.altKey && key === 79) // alt + o
            document.getElementById('new-servie-link').click();

        if (e.altKey && key === 67)  // alt + c
            document.getElementById('new-client-link').click();

        if (e.altKey && key === 85)
            document.getElementById('new-user-link').click();
    };

    /*------------------------------------------------
        Clock
    -------------------------------------------------*/
    if ($('.time')[0]) {
        let newDate = new Date();
        newDate.setDate(newDate.getDate());

        setInterval(function () {
            let seconds = new Date().getSeconds();
            $('.time__sec').html((seconds < 10 ? '0' : '') + seconds);
        }, 1000);

        setInterval(function () {
            let minutes = new Date().getMinutes();
            $('.time__min').html((minutes < 10 ? '0' : '') + minutes);
        }, 1000);

        setInterval(function () {
            let hours = new Date().getHours();
            $('.time__hours').html((hours < 10 ? '0' : '') + hours);
        }, 1000);
    }


});

/*------------------------------------------------
    @Custom actions / functions
-------------------------------------------------*/

// Verify location.hash and do something about it ;)
function verifyHash() {
    if (location.hash === '' || location.hash === '#_') {
        // Removes modal
        $('#global-modal').modal('hide');

        // Removes slidebar if it's visible
        if (document.querySelector('.ma-backdrop-side-panel')) {
            $('[data-ma-action="aside-open"], .side-panel').removeClass('toggled');
            $('body').css('overflow', 'auto');
            $('.content, .header').find('.ma-backdrop-side-panel').remove();
            $('#side-panel-body').html('');
            $('#side-panel-title').html('');
            document.title = document.getElementsByTagName('title')[0].getAttribute('data-original');
        }
    } else {
        // If this is an action hash
        let hash = location.hash.substr(1)
            .split("&")
            .map(el => el.split("="))
            .reduce((pre, cur) => {
                pre[cur[0]] = cur[1];
                return pre;
            }, {});

        switch (hash.action) {
            case 'show':
                loadSidePanelView(hash.url, hash.data);
                break;
            case 'show.modal':
                loadModal(hash.url, hash.data);
                break;
            default:
                break;
        }
    }
}

/*------------------------------------------------
    Form group blue line function
-------------------------------------------------*/
function formGroupBlueLine() {
    if ($('.form-group--float')[0]) {
        $('.form-group--float').each(function () {
            var p = $(this).find('.form-control').val();

            if (!p.length == 0) {
                $(this).find('.form-control').addClass('form-control--active');
            }
        });

        $('body').on('blur', '.form-group--float .form-control', function () {
            var i = $(this).val();

            if (i.length == 0) {
                $(this).removeClass('form-control--active');
            } else {
                $(this).addClass('form-control--active');
            }
        });

        $(this).find('.form-control').change(function () {
            var x = $(this).val();

            if (!x.length == 0) {
                $(this).find('.form-control').addClass('form-control--active');
            }
        });
    }
}

/**
 * Show / Hide edit icon from list views
 * @custom
 *
 */
function toggleListItemActions() {
    var listViewItem = $('.listview__item');
    if (listViewItem) {
        listViewItem.each(function () {
            $(this).on('mouseover', function () {
                var li = $(this);
                li.find('[id ^=list-item-actions]').removeClass('hidden-sm-up');
            });
            $(this).on('mouseout', function () {
                var li = $(this);
                li.find('[id ^=list-item-actions]').addClass('hidden-sm-up');
            })
        });
    }
}

/**
 * Apply form masks
 * @custom
 *
 */
function setMasks() {
    $('input').unmask();

    $('.mask-dateTime').each(function () {
        $(this).mask('99/99/9999 99:99:99');
    });
    $('.mask-time').each(function () {
        $(this).mask('99:99');
    });
    $('.mask-date').each(function () {
        $(this).mask('99/99/9999');
    });
    $('.mask-dateMonthYear').each(function () {
        $(this).mask('99/9999');
    });
    $('.mask-cpf').each(function () {
        $(this).mask('999.999.999-99')
    });
    $('.mask-cnpj').each(function () {
        $(this).mask('99.999.999/9999-99')
    });
    $('.mask-cep').each(function () {
        $(this).mask('99999-999')
    });
    $('.mask-phone').each(function () {
        $(this).mask('ZZZ (00) 00000-0000',{
            translation: {
                'Z': {
                    pattern: /[0-9]/, optional: true
                }
            }
        })
    });
    $('.mask-phone-without-country').each(function () {
        $(this).mask('(00) 00000-0000')
    });
    $('.mask-money').each(function () {
        $(this).mask('#.##0,00', {reverse: true});
    });
}

var checked        = [];
var listIdCheckbox = $('input[name="list_id[]"]');

/** Check'em all */
$('#list-check-all').on('change', function () {
    listIdCheckbox.prop('checked', this.checked);
    if (this.checked) {
        checked = [];
        listIdCheckbox.each(function () {
            checked.push($(this).val());
        });
    } else {
        checked = [];
    }
    verifyCheckboxes();
});

// Select to delete
listIdCheckbox.on('click', function () {
    if ($(this).is(':checked')) {
        checked.push($(this).val());
    } else {
        let removeItem = $(this).val();
        checked        = $.grep(checked, function (value) {
            return value != removeItem;
        });
    }
    verifyCheckboxes();
});

// Verify (un)checked checkboxes
function verifyCheckboxes() {

    // Hidden input with all delete intended IDs
    $('input[name="destroy_ids"]').val(checked);

    if (checked.length > 0) {
        $('#hidden-action-fixed-buttons').removeClass('hidden-xs-up').addClass('animated bounceIn');
    } else {
        $('#hidden-action-fixed-buttons').addClass('hidden-xs-up').removeClass('animated bounceIn');
    }
}

/**
 * Delete intent confirmation
 */
function deleteIntent() {
    if (checked.length > 0) {
        swal({
            title             : "Você deseja apagar o(s) item(s) selecionado(s)?",
            text              : "Não será possível recuperar estes registros posteriormente.",
            type              : "warning",
            showCancelButton  : true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText : 'Sim, quero apagar.',
            cancelButtonText  : "Não, cancele a operação!",
            closeOnConfirm    : true,
            closeOnCancel     : true
        }).then(result => {
            if (result.value) {
                document.getElementById('form-actions').submit();
            } else {
                //console.log('Clicou em cancelar');
            }
        });
    } else {
        swal('Nenhum item foi selecionado', 'Escolha algum item da lista primeiro.', 'info');
    }
}

/**
 * Reset a given form
 *
 * @param $form
 * @param $submit
 */
function formReset($form, $submit) {
    $form.find('input:text, input:password, input:file, select, textarea').val('');
    $form.find('input:radio, input:checkbox')
        .removeAttr('checked').removeAttr('selected');

    if (document.getElementById('filter'))
        document.getElementById('filter').value = 0;

    if ($submit)
        $form.submit();
}

/**
 * Show the search filters if there is any filter applied (XS version)
 *
 */
function toggleSearchForm() {
    let filter     = window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent('filter').replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1");
    let formFilter = document.getElementById('form-filter');
    if (formFilter) {
        if (filter === '1')
            formFilter.classList.remove('hidden-xs-down');
        else
            formFilter.classList.add('hidden-xs-down');
    }
}

/**
 * Notifications (bootstrap notify)
 *
 */
function notify(nTitle, nMessage, nDelay, type, from, align, icon, animIn, animOut) {
    $('.alert--notify').remove();
    $.notify({
        newest_on_top: true,
        icon         : icon,
        title        : nTitle,
        message      : nMessage,
        url          : ''
    }, {
        element      : 'body',
        type         : type,
        allow_dismiss: true,
        placement    : {
            from : from,
            align: align
        },
        offset       : {
            x: 20,
            y: 20
        },
        spacing      : 10,
        z_index      : 1031,
        delay        : (isNaN(nDelay)) ? 2500 : nDelay,
        timer        : 1000,
        url_target   : '_blank',
        mouse_over   : false,
        animate      : {
            enter: 'animated fadeIn',
            exit : animOut
        },
        template     : '<div data-notify="container" class="alert alert-dismissible alert-{0} alert--notify" role="alert">' +
            '<span data-notify="icon"></span> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            '</div>' +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
            '<button type="button" aria-hidden="true" data-notify="dismiss" class="alert--notify__close">X</button>' +
            '</div>'
    });
}

function loadSidePanelView(url, data) {
    // First, clean up the backdrop
    $('#aside-open-link').trigger('click');
    $('#side-panel-title').html(
        '<i class="zmdi zmdi-settings zmdi-hc-spin pull-left mr-3">' +
        '</i><i class="zmdi zmdi-cloud-outline-alt pull-left animated fadeIn infinite mr-3"></i> ' +
        'Carregando');

    $('.tooltip').tooltip('hide');
    let ajax = new XMLHttpRequest();
    ajax.open('GET', url, true);
    ajax.setRequestHeader('Accept', 'application/json');
    ajax.responseType = 'json';

    ajax.onload = function () {
        let data = ajax.response;
        if (ajax.status === 200) {
            document.getElementById('side-panel-title').innerText = data.title;
            document.getElementById('side-panel-body').innerHTML  = data.body;

            let body    = document.getElementById('side-panel-body');

            let scripts = body.querySelectorAll('script');
            scripts.forEach((script) => {
                let scriptTag = document.createElement("script");
                if (script.getAttribute('src') !== null)
                    scriptTag.setAttribute('src', script.getAttribute('src'));
                scriptTag.textContent = script.textContent;
                body.removeChild(script);
                body.appendChild(scriptTag);
            });

            // Re-run some document ready functions
            formGroupBlueLine();
            initTooltip();
            initInnerScrollbar();
            setJSTriggers();

            // Go to top
            document.querySelector('#side-panel-body').scrollTop = 0;
        } else {
            swal('Alerta', 'Este registro não foi encontrado no sistema', 'warning');
            $('#side-panel-close-link').click();
        }
    };

    ajax.send(data);
}

function loadView(url, data, targetId) {
    let ajax = new XMLHttpRequest();
    ajax.open('GET', url, true);
    ajax.setRequestHeader('Accept', 'application/json');
    ajax.responseType = 'json';

    ajax.onload = function () {
        let data            = ajax.response;
        let targetContainer = document.getElementById(targetId);
        if (ajax.status === 200) {

            targetContainer.innerHTML = data.body;

            let scripts = targetContainer.querySelectorAll('script');
            scripts.forEach((script) => {
                let scriptTag = document.createElement('script');
                if (script.getAttribute('src') !== null)
                    scriptTag.setAttribute('src', script.getAttribute('src'));
                scriptTag.textContent = script.textContent;
                targetContainer.removeChild(script);
                targetContainer.appendChild(scriptTag);
            });

            // Re-run some document ready functions
            formGroupBlueLine();
            initTooltip();
            initInnerScrollbar();
            setJSTriggers();

        } else {
            targetContainer.innerHTML = '';
            swal('Alerta', 'Este registro não foi encontrado no sistema', 'warning');
        }
    };

    ajax.send(data);
}

/*------------------------------------------------
    Open Modal & load content into it
-------------------------------------------------*/
window.loadModal = (url, data) => {

    $('#global-modal').modal('show');

    let modalContent = document.getElementById('modal-content');

    modalContent.innerHTML = '<h5 class="p-4 text-gray-dark">' +
        '<i class="zmdi zmdi-settings zmdi-hc-spin pull-left mr-3">' +
        '</i><i class="zmdi zmdi-cloud-outline-alt pull-left animated fadeIn infinite mr-3"></i> ' + 'Carregando' +
        '</h5>';

    $('.tooltip').tooltip('hide');

    let ajax = new XMLHttpRequest();
    ajax.open('GET', url, true);
    ajax.setRequestHeader('Accept', 'application/json');
    ajax.responseType = 'json';

    ajax.onload = function () {
        let data = ajax.response;
        if (ajax.status === 200) {
            modalContent.innerHTML = data.body;

            let scripts = modalContent.querySelectorAll('script');

            scripts.forEach((script) => {
                let scriptTag = document.createElement("script");
                if (script.getAttribute('src') !== null)
                    scriptTag.setAttribute('src', script.getAttribute('src'));
                scriptTag.textContent = script.textContent;
                modalContent.removeChild(script);
                modalContent.appendChild(scriptTag);
            });

            // Re-run some document ready functions
            formGroupBlueLine();
            initTooltip();
            initInnerScrollbar();
            setJSTriggers();

        } else {
            swal('Alerta', 'Este registro não foi encontrado no sistema', 'warning');
            $('#global-modal').modal('hide');
        }
    };

    ajax.send(data);
};

/*------------------------------------------------
    Ajax save
-------------------------------------------------*/
function ajaxSave(method, url, formId, callback, customFormData) {

    $('.tooltip').tooltip('hide')

    let formData = (formId.length > 3) ? new FormData(document.querySelector(formId)) : new FormData;
    if (customFormData) {
        for (let key in customFormData) {
            if (customFormData.hasOwnProperty(key))
                formData.append(key, customFormData[key])
        }
    }
    let ajax = new XMLHttpRequest()
    ajax.open(method, url, true)
    ajax.setRequestHeader('Accept', 'application/json')
    ajax.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name=csrf-token]').getAttribute('content'))
    ajax.responseType = 'json'

    ajax.onload = function () {

        unsetButtonLoadingState(formId);

        if (ajax.status === 200) {
            let data = ajax.response;
            if (callback) callback(data)
            if (data.message !== '') {
                if (data.reload === true) {
                    swal({
                        title            : "",
                        type             : data.alert_type,
                        html             : data.message,
                        showCloseButton  : false,
                        showCancelButton : false,
                        confirmButtonText: 'OK',
                        allowOutsideClick: false,
                        allowEscapeKey: false
                    }).then(result => {
                        if (result.value) {
                            if (data.reload_url) {
                                location.replace(data.reload_url);
                            } else {
                                location.reload();
                            }
                        } else {
                            //console.log('Clicou em cancelar');
                        }
                    });
                } else {
                    notify('', data.message, '100000', data.alert_type, 'bottom', 'left');

                    let changeableElements = document.querySelectorAll('[data-ajax-changeable]');
                    if (changeableElements[0]) {
                        changeableElements.forEach((element) => {
                            let dataChangeable = element.dataset.ajaxChangeable.split('|');
                            let elementModel   = dataChangeable[0];
                            let elementId      = parseInt(dataChangeable[1]);
                            let elementField   = dataChangeable[2];
                            if ((data.model.id === elementId) && (data.model.model === elementModel)) {
                                for (let modelField in data.model) {
                                    let modelValue = data.model[modelField];
                                    if ((elementField === modelField) &&
                                        (data.model[modelField] !== null)) {
                                        element.textContent = modelValue;
                                        element.classList.add('animated', 'flash');
                                    }
                                }
                            }
                        });
                    }
                }
            }
        } else {
            let error = JSON.parse(JSON.stringify(ajax.response));
            error     = (error.length === 0) ? "Erro não identificado. Entre em contato com o responsável pelo sistema." : error;
            if (typeof error === 'object') {
                let errorMessages = '';
                for (let key in error) {
                    if (error.hasOwnProperty(key))
                        errorMessages += error[key] + '<br>';
                }
                error = errorMessages;
            }
            swal("Erro(s) encontrado(s):", error, "error");
        }
    }
    ajax.send(formData);
    setButtonLoadingState(formId);
}

function setButtonLoadingState(formId) {
    if (formId.length >= 3) {
        let mainSubmitButton = document.querySelector(formId + ' button[type="submit"]');
        if (mainSubmitButton) {
            mainSubmitButton.disabled                = 'disabled';
            mainSubmitButton.childNodes[1].classList = '';
            mainSubmitButton.childNodes[1].classList.add('zmdi', 'zmdi-settings', 'zmdi-hc-spin');
        }
    }
}

function unsetButtonLoadingState(formId) {
    if (formId.length >= 3) {
        let mainSubmitButton = document.querySelector(formId + ' button[type="submit"]');
        if (mainSubmitButton) {
            mainSubmitButton.removeAttribute('disabled');
            mainSubmitButton.childNodes[1].classList = '';
            mainSubmitButton.childNodes[1].classList.add('zmdi', 'zmdi-floppy');
        }
    }
}

function moveCursorToEnd(el) {
    if (typeof el.selectionStart == "number") {
        el.selectionStart = el.selectionEnd = el.value.length;
    } else if (typeof el.createTextRange != "undefined") {
        el.focus();
        let range = el.createTextRange();
        range.collapse(false);
        range.select();
    }
}

function replaceSpecialChars(string)
{
    let special = "ÄÅÁÂÀÃäáâàãÉÊËÈéêëèÍÎÏÌíîïìÖÓÔÒÕöóôòõÜÚÛüúûùÇç";
    let regular = "AAAAAAaaaaaEEEEeeeeIIIIiiiiOOOOOoooooUUUuuuuCc";

    for (let i = 0; i < special.length; i++) {
        string = string.replace(special[i].toString(), regular[i].toString());
    }
    return string;
}

/**
 * Initialize service groups, statuses and priorities dropdowns
 *
 */
function initGroupStatusPriorityDropdowns(){
    $('.group-item').on('click', function () {
        $('#service-item-group-id').val($(this).data('group-id'));
        $('#group-name-span').html('<i class="zmdi zmdi-circle text-' + $(this).data('group-color') + '"></i>&nbsp;' + $(this).data('group-name'));
    });

    $('.js-status-dropdown-changed').on('click', function () {
        $('#service-status-id').val($(this).data('status-id'));
        $('#status-name-span').html('<i class="zmdi zmdi-circle" style="color:'+$(this).data('status-color')+'"></i>&nbsp;' + $(this).data('status-name'));
    });

    $('.js-priority-dropdown-changed').on('click', function () {
        $('#service-priority-id').val($(this).data('priority-id'));
        $('#priority-name-span').html('<i class="zmdi zmdi-circle" style="color:'+$(this).data('priority-color')+'"></i>&nbsp;' + $(this).data('priority-name'));
    });
}

